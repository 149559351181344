<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <div style="margin-bottom: 10px">
      <a-radio-group default-value="Booking" @change="typeChange" button-style="solid">
        <a-radio-button v-for="v in goodsTypes" :key="v.value" :value="v.value">{{ v.label }}</a-radio-button>
      </a-radio-group>
    </div>
    <div>
      <a-radio-group default-value="A" @change="siteChange" button-style="solid">
        <a-radio-button v-for="v in siteOptions" :key="v.value" :value="v.value">{{ v.label }}</a-radio-button>
      </a-radio-group>
    </div>
    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">
      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">
        <!-- Authors Table Card -->
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <a-space>
                  <h5 class="font-semibold m-0">订单数据</h5>
                </a-space>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                <a-space>
                  <a-button type="default" :loading="refreshLoading" size="small" @click="getTmOrders">刷新</a-button>
                  <a-button type="primary" size="small" @click="showModal">新增订单</a-button>
                </a-space>

                <!-- <a-radio-group v-model="authorsHeaderBtns" size="small">
                    <a-radio-button value="all">抢票</a-radio-button>
                    <a-radio-button value="online">暂停</a-radio-button>
                </a-radio-group>-->
              </a-col>
            </a-row>
          </template>
          <a-table :columns="columns" size="small" rowKey="id" :data-source="dataSource" :pagination="false">
            <template slot="admission_site_name" slot-scope="admission_site_name">
              <div class="table-avatar-info">
                <div class="avatar-info">{{ admission_site_name }}</div>
              </div>
            </template>
            <template slot="line_name" slot-scope="line_name">
              <div class="table-avatar-info">
                <div class="avatar-info">{{ line_name }}</div>
              </div>
            </template>
            <template slot="period_name" slot-scope="period_name">
              <div class="table-avatar-info">
                <div class="avatar-info">{{ period_name }}</div>
              </div>
            </template>
            <template slot="type" slot-scope="type">
              <div class="table-avatar-info">
                <div class="avatar-info">{{ type === 'SKBooking' ? '散客预订' : '网络预订' }}</div>
              </div>
            </template>
            <template slot="status" slot-scope="status">
              <div class="table-avatar-info">
                <div class="avatar-info" v-if="statusEnum[status]">
                  <a-tag :color="statusEnum[status].color">{{ statusEnum[status].text }}</a-tag>
                </div>
              </div>
            </template>
            <template slot="goods" slot-scope="goods">
              <div class="avatar-info" v-for="(user, index) in goods" :key="index">
                <h6>{{ user.good_name }}</h6>
                <p v-for="(author, idx) in user.customers" :key="idx">{{ author.name }} {{ author.id_num }} {{ author.phone }}</p>
              </div>
            </template>
            <template slot="editBtn" slot-scope="row">
              <a-space>
                <a-button type="link" v-if="row.status === 5" :data-id="row.status" @click="editOrder(row)">编辑</a-button>
                <a-button type="link" v-if="row.status === 5" :data-id="row.status" @click="updateStatus(row.id, 10)">抢票</a-button>
                <a-button type="link" v-if="row.status === 9" :data-id="row.status" @click="updateStatus(row.id, 10)">抢票</a-button>
                <a-button type="link" v-if="row.status === 9" :data-id="row.status" @click="showErr(row.error_msg)">原因</a-button>
                <a-button type="link" v-if="row.status === 10" :data-id="row.status" @click="updateStatus(row.id, 5)">暂停</a-button>
                <a-button type="link" v-if="row.status === 15" :data-id="row.status" @click="viewQrCode(row)">支付二维码</a-button>
              </a-space>
            </template>
          </a-table>
          <a-card style="display: flex; justify-content: end">
            <a-pagination v-model="current" :total="total" @change="paginationChange" show-less-items />
          </a-card>
        </a-card>
      </a-col>
    </a-row>
    <a-modal v-model="qrcodeVisible" title="支付信息" ok-text="确定" cancel-text="取消" @ok="hideModal">
      <div>
        <qr-code :text="payInfo.qrcode" size="300"></qr-code>
        <!-- <p>
          <img :src="payInfo.qrcode" width="300" height="300" style="color: red;" />
        </p>-->
        <p>支付金额：{{ payInfo.price }}</p>
        <p>支付数量：{{ payInfo.count }}人</p>
        <p>订单编号：{{ payInfo.order_no }}</p>
      </div>
    </a-modal>
    <!-- / Authors Table -->
    <a-modal v-model="visible" title="新增/编辑订单" :width="800">
      <template #footer>
        <!-- <a-button key="back" @click="handleCancel">Return</a-button> -->
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">Submit</a-button>
      </template>
      <a-form ref="orderForm" :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
        <a-form-item v-show="false" label="订单ID">
          <a-input v-decorator="['id', { rules: [] }]" />
        </a-form-item>
        <a-row :gutter="24" type="flex">
          <!-- Authors Table Column -->
          <a-col :span="12" class="mb-12">
            <a-form-item label="入园站点">
              <a-select
                v-decorator="['admission_site', { rules: [{ required: true, message: '请选择入园站点!' }] }]"
                placeholder="选择入园站点"
              >
                <a-select-option v-for="v in booking.admission_site.value" :key="v[1]" :value="v[0]">{{ v[1] }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-12">
            <a-form-item label="游玩路线">
              <a-select v-decorator="['line', { rules: [{ required: true, message: '请选择游玩路线!' }] }]" placeholder="选择游玩路线">
                <a-select-option v-for="v in booking.line.value" :key="v[1]" :value="v[0]">{{ v[1] }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-12">
            <a-form-item label="游玩时间段">
              <a-select
                v-decorator="['period', { rules: [{ required: true, message: '请选择游玩时间段!' }] }]"
                placeholder="选择游玩时间段"
              >
                <a-select-option v-for="v in booking.period.value" :key="v[0]" :value="v[0]">{{ v[1] }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12" class="mb-12">
            <a-form-item label="游玩日期">
              <a-date-picker
                value-format="YYYY-MM-DD"
                v-decorator="['play_date', { rules: [{ required: true, message: '请选择游玩日期!' }] }]"
                :locale="locale"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-12">
            <a-form-item label="游客姓名">
              <a-input v-decorator="['link_name', { rules: [{ required: true, message: '请输入游客姓名!' }] }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-12">
            <a-form-item label="身份证号">
              <a-input v-decorator="['link_id_num', { rules: [{ required: true, message: '请输入身份证号码!' }] }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12" class="mb-12">
            <a-form-item label="手机号">
              <a-input v-decorator="['link_phone', { rules: [{ required: true, message: '请输入手机号码!' }] }]" />
            </a-form-item>
          </a-col>
          <a-col :span="24" class="mb-24 customers">
            <a-space v-for="(user, index) in goods" :key="user.key" style="display: flex; margin-bottom: 8px" align="center">
              <a-form-item>
                <a-select
                  v-decorator="[`reservable_tickets_${user.key}`, { rules: [{ required: true, message: '请选择可预订的门票!' }] }]"
                  placeholder="可预订的门票"
                  style="width: 220px"
                >
                  <a-select-option v-for="v in booking.reservable_tickets.value" :key="v[1]" :value="v[0]"
                    >{{ v[1] }} ¥{{ v[2] }}</a-select-option
                  >
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-input
                  v-decorator="[`name_${user.key}`, { rules: [{ required: true, message: '请输入游客姓名!' }] }]"
                  placeholder="游客姓名"
                  style="width: 100px"
                />
              </a-form-item>
              <a-form-item>
                <a-input
                  v-decorator="[`id_num_${user.key}`, { rules: [{ required: true, message: '请输入身份证号码!' }] }]"
                  placeholder="身份证号码"
                  style="width: 180px"
                />
              </a-form-item>
              <a-form-item :name="['users', index, 'phone']">
                <a-input v-decorator="[`phone_${user.key}`, { rules: [] }]" placeholder="电话号码" style="width: 120px" />
              </a-form-item>
              <a-icon type="minus-circle" theme="outlined" @click="removeUser(user)" />
            </a-space>
            <a-form-item>
              <a-button type="dashed" block @click="addUser"> <a-icon type="plus" theme="outlined" />添加可预订门票/游客 </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import VueQRCodeComponent from 'vue-qrcode-component'
import { uniqueId, startsWith, words, last, groupBy, forEach, flatten } from 'lodash'
import { getTmGoods, getTmOrders, saveTmOrders, updateTmOrders, updateOrderStatus, getTmSite } from '@/api/index'
// 0 取消 5暂停 9抢票失败 10抢票中 15待支付 20支付成功
const statusEnum = {
  0: { color: 'default', text: '取消' },
  5: { color: '#f50', text: '暂停' },
  9: { color: 'default', text: '失败' },
  10: { color: '#108ee9', text: '抢票中' },
  15: { color: '#2db7f5', text: '待支付' },
  20: { color: '#87d068', text: '支付成功' }
}
const columns = [
  {
    title: '入园站点',
    dataIndex: 'admission_site_name',
    scopedSlots: { customRender: 'admission_site_name' },
    width: 140
  },
  {
    title: '游玩线路',
    dataIndex: 'line_name',
    scopedSlots: { customRender: 'line_name' },
    width: 160
  },
  {
    title: '游玩时间段',
    dataIndex: 'period_name',
    scopedSlots: { customRender: 'period_name' },
    width: 120
  },
  {
    title: '游玩日期',
    dataIndex: 'play_date',
    scopedSlots: { customRender: 'play_date' },
    width: 140
  },

  {
    title: '联系人姓名',
    dataIndex: 'link_name',
    scopedSlots: { customRender: 'link_name' },
    width: 120
  },
  {
    title: '联系人身份证号',
    dataIndex: 'link_id_num',
    class: 'font-semibold text-muted',
    width: 200
  },
  {
    title: '联系人手机号',
    dataIndex: 'link_phone',
    class: 'font-semibold text-muted text-sm',
    width: 140
  },
  {
    title: '可预定门票/游客',
    scopedSlots: { customRender: 'goods' },
    dataIndex: 'goods'
  },
  {
    title: '类型',
    scopedSlots: { customRender: 'type' },
    dataIndex: 'type'
  },
  {
    title: '状态',
    scopedSlots: { customRender: 'status' },
    dataIndex: 'status'
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'editBtn' },
    width: 50
  }
]
export default {
  components: {
    'qr-code': VueQRCodeComponent
  },
  data() {
    return {
      current: 1,
      total: 0,
      authorsHeaderBtns: 'all',
      goods: [],
      tm_site: 'A',
      bookType: 'Booking',
      siteOptions: [],
      entrySites: [],
      tourRoutes: [],
      ticketsMap: {},
      payInfo: {},
      qrcodeVisible: false,
      refreshLoading: false,
      loading: false,
      locale: locale,
      visible: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      // Associating "Authors" table data with its corresponding property.
      dataSource: [],
      goodsTypes: [
        {
          label: '网络预定',
          value: 'Booking'
        },
        {
          label: '散客预定',
          value: 'SKBooking'
        }
      ],
      // Associating "Authors" table columns with its corresponding property.
      columns: columns,
      statusEnum: statusEnum,
      booking: {
        admission_site: {
          value: []
        },
        line: {
          value: []
        },
        period: {
          value: []
        }
      }
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows)
        }
      }
    }
  },
  created() {
    // debugger
    this.getTmSite()
  },
  methods: {
    typeChange(e) {
      //   debugger
      this.bookType = e.target.value
      this.current_page = 1
      this.loadSite(this.tm_site)
    },
    hideModal() {
      this.qrcodeVisible = false
    },
    showErr(msg) {
      this.$info({
        title: '失败原因',
        content: msg,
        okText: '知道了',
        onOk() {}
      })
    },
    viewQrCode(row) {
      // debugger payInfo ={price,count,order_no,qrcode}
      if (row.pay && row.pay.result) {
        const { result } = row.pay
        // const h = this.$createElement
        // this.$info({
        //   title: '支付信息',
        //   content: h('div', {}, [
        //     h('p', {}, [
        //       h('img', {
        //         attrs: { src: result.qrcode, width: 300, height: 300 },
        //         style: { color: 'red' }
        //       })
        //     ]),
        //     h('p', `支付金额：${result.price}`),
        //     h('p', `支付数量：${result.count}`),
        //     h('p', `订单编号：${result.order_no}`)
        //   ]),
        //   okText: '知道了',
        //   onOk() {}
        // })

        this.payInfo = result
        this.qrcodeVisible = true
      }
    },
    getTmSite() {
      getTmSite().then(res => {
        const { code, data } = res

        if (code === 200 && data.admission_site && Array.isArray(data.admission_site.options)) {
          this.siteOptions = data.admission_site.options
          this.tm_site = this.siteOptions[0].value
          this.loadSite(this.tm_site)
        }
      })
    },
    loadSite(site) {
      this.getTmGoods(site)
      this.getTmOrders()
    },
    siteChange(e) {
      console.log('siteChange--', e.target.value)
      this.tm_site = e.target.value
      this.loadSite(e.target.value)
    },
    paginationChange(page) {
      this.getTmOrders(page)
    },
    editOrder(row) {
      this.visible = true
      // debugger
      const groupGoods = row.goods.map(el => {
        //reservable_tickets_ name_ id_num_ phone_
        const uuid = uniqueId()
        return el.customers.map(e => ({
          [`reservable_tickets_${e.id}_${uuid}`]: el.good_id,
          [`name_${e.id}_${uuid}`]: e.name,
          [`id_num_${e.id}_${uuid}`]: e.id_num,
          [`phone_${e.id}_${uuid}`]: e.phone,
          key: `${e.id}_${uuid}`
        }))
      })
      const flattenGoods = flatten(groupGoods)
      this.goods = flattenGoods
      let goodsFields = {}
      flattenGoods.forEach(item => {
        forEach(item, (value, key) => {
          if (key !== 'key') {
            goodsFields[key] = value
          }
        })
      })
      const fields = {
        id: row.id,
        admission_site: row.admission_site,
        line: row.line,
        period: row.period,
        play_date: row.play_date,
        link_name: row.link_name,
        link_id_num: row.link_id_num,
        link_phone: row.link_phone,
        ...goodsFields
      }
      setTimeout(() => {
        this.form.setFieldsValue(fields)
      }, 1000)
    },
    updateStatus(orderId, statusId) {
      updateOrderStatus(orderId, statusId).then(res => {
        const { code, msg } = res
        if (code === 200) {
          this.getTmOrders()
        } else {
          this.$message.error(msg)
        }
      })
    },
    getTmGoods(site) {
      const ticketsMap = {}
      getTmGoods(site, this.bookType).then(res => {
        const { code, data } = res
        if (code === 200) {
          this.booking = data
          console.log('getTmGoods---', res)
          res.data.reservable_tickets.value.forEach(item => {
            ticketsMap[item[0]] = item[1]
          })
          this.entrySites = data.admission_site.value
          this.tourRoutes = data.line.value
          this.ticketsMap = ticketsMap
        }
      })
    },
    getTmOrders(page) {
      const params = { page: page || 1, page_size: 30, admission_site: this.tm_site, type: this.bookType }
      this.refreshLoading = true
      getTmOrders(params)
        .then(res => {
          this.refreshLoading = false
          const { code, data, msg } = res
          if (code === 200) {
            this.dataSource = data.data
            this.total = data.total
            this.current = data.current_page
          }
          // this.table1Data = res.data
        })
        .catch(() => {
          this.refreshLoading = false
        })
    },
    removeUser(item) {
      let index = this.goods.indexOf(item)
      if (index !== -1) {
        this.goods.splice(index, 1)
      }
    },
    addUser() {
      this.goods.push({
        key: uniqueId()
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },

    showModal() {
      this.form.resetFields()
      // this.form.admission_site = this.tm_site
      this.visible = true
    },
    handleOk(e) {
      e.preventDefault()
      this.loading = true
      this.form.validateFields((err, values) => {
        if (!err) {
          debugger
          console.log('Received values of form: ', values)
          let tickets = {}
          Object.keys(values).forEach(k => {
            if (startsWith(k, 'reservable_tickets_') || startsWith(k, 'name_') || startsWith(k, 'id_num_') || startsWith(k, 'phone_')) {
              const wordsArr = words(k, /[^_ ]+/g)
              const uq = 't_' + last(wordsArr)

              if (typeof tickets[uq] === 'undefined') {
                tickets[uq] = {}
              }
              if (wordsArr.length > 1) {
                // debugger
                const tid = wordsArr.slice(-2)[0]
                if (!isNaN(tid)) {
                  tickets[uq]['id'] = tid
                }
              }
              if (startsWith(k, 'reservable_tickets_')) {
                tickets[uq]['reservable_tickets'] = values[k]
              }
              if (startsWith(k, 'name_')) {
                tickets[uq]['name'] = values[k]
              }
              if (startsWith(k, 'id_num_')) {
                tickets[uq]['id_num'] = values[k]
              }
              if (startsWith(k, 'phone_')) {
                tickets[uq]['phone'] = values[k]
              }
            }
          })
          // debugger
          const ticketValues = Object.values(tickets)
          const ticketGroup = groupBy(ticketValues, 'reservable_tickets')
          const goodsTicket = []
          forEach(ticketGroup, (value, key) => {
            goodsTicket.push({
              good_id: key,
              good_name: this.ticketsMap[key],
              customers: value
            })
          })
          console.log('goodsTicket---', goodsTicket)
          values.type = this.bookType
          values.goods = goodsTicket
          if (values.admission_site) {
            try {
              // const bookingAdmissionSites = typeof(this.booking.admission_site.value)==='object' ? Object.values()
              if (typeof this.booking.admission_site.value === 'object') {
                values.admission_site = Object.values(this.booking.admission_site.value).filter(el => el[0] === values.admission_site)[0]
              } else {
                values.admission_site = this.booking.admission_site.value.filter(el => el[0] === values.admission_site)[0]
              }
            } catch (error) {
              console.log('error---', error)
              this.loading = false
            }
          }
          // values.admission_site =
          if (values.line) {
            values.line = this.booking.line.value.filter(el => el[0] === values.line)[0]
          }
          if (values.period) {
            debugger
            switch (values.period) {
              case '2':
                values.period = this.booking.period.value[0]
                break
              case '4':
                values.period = this.booking.period.value[1]
                break
              case '6':
                values.period = this.booking.period.value[2]
                break
              case '9':
                values.period = this.booking.period.value[3]
                break
            }
          }

          values.status = '5'
          if (values.id) {
            updateTmOrders(values, values.id)
              .then(res => {
                console.log('saveTmOrders--', res)
                this.loading = false
                this.visible = false
                this.getTmOrders()
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            saveTmOrders(values)
              .then(res => {
                console.log('saveTmOrders--', res)
                this.loading = false
                this.visible = false
                this.getTmOrders()
              })
              .catch(() => {
                this.loading = false
              })
          }
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.customers {
  ::v-deep .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
